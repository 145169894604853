.wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.wrapper > * {
  margin: 12px 12px 12px 0;
  min-width: 280px;
  width: 280px;
}