.wrapper > * {
  margin-top: 12px;
}

.wrapper :global(.ant-input) {
  font-size: 10px;
}

.wrapper :global(.ant-select) {
  font-size: 10px;
}