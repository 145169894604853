@import '~antd/dist/antd.css';

body {
  background: #f0f2f5;
}

.logo {
  max-height: 100%;
  padding: 16px 0;
}

.ant-layout-header {
  padding: 0 16px;
  background: transparent;
}

.ant-col {
  padding: 4px 0;
}

.droppable-inside.ant-card-small > .ant-card-body {
  padding: 0;
}

.ant-collapse-header {
  align-items: center;
  display: flex;
}

.ant-collapse-header > .ant-input {
  width: auto;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
