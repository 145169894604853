body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: #f0f2f5;
}

.logo {
  max-height: 100%;
  padding: 16px 0;
}

.ant-layout-header {
  padding: 0 16px;
  background: transparent;
}

.ant-col {
  padding: 4px 0;
}

.droppable-inside.ant-card-small > .ant-card-body {
  padding: 0;
}

.ant-collapse-header {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

.ant-collapse-header > .ant-input {
  width: auto;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.CodeList_wrapper__t_O2v > * {
  margin-top: 12px;
}

/* .wrapper :global(.ant-badge-status-dot) {
  width: 8px;
  height: 8px;
} */
.PropertyList_wrapper__k2RsE > * {
  margin-top: 12px;
}

.PropertyList_wrapper__k2RsE .ant-input {
  font-size: 10px;
}

.PropertyList_wrapper__k2RsE .ant-select {
  font-size: 10px;
}
.CategoryList_wrapper__bLB1O {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow-x: auto;
}

.CategoryList_wrapper__bLB1O > * {
  margin: 12px 12px 12px 0;
  min-width: 280px;
  width: 280px;
}
.editable-row-dynamicsnavids .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
.table-products td {
  white-space: nowrap;
}
